exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-automobile-engine-oil-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/automobile/engine-oil.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-automobile-engine-oil-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-automobile-run-out-of-gasoline-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/automobile/run-out-of-gasoline.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-automobile-run-out-of-gasoline-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-car-byd-atto-3-tesla-threat-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/ev-car/byd-atto-3-tesla-threat.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-car-byd-atto-3-tesla-threat-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-car-ora-good-cat-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/ev-car/ora-good-cat.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-car-ora-good-cat-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-ev-automobile-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/ev/ev-automobile.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-ev-automobile-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-mg-zs-ev-charge-time-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/ev/mg-zs-ev-charge-time.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-ev-mg-zs-ev-charge-time-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-insurance-compulsory-motor-insurance-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/insurance/compulsory-motor-insurance.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-insurance-compulsory-motor-insurance-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-insurance-compulsory-motor-insurance-price-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/insurance/compulsory-motor-insurance-price.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-insurance-compulsory-motor-insurance-price-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-insurance-insurance-level-introduction-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/insurance/insurance-level-introduction.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-insurance-insurance-level-introduction-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-elephant-shelter-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/elephant-shelter.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-elephant-shelter-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-emerald-pool-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/emerald-pool.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-emerald-pool-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-koh-klang-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/koh-klang.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-koh-klang-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-krabi-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/krabi.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-krabi-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-maya-beach-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/maya-beach.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-maya-beach-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-phi-phi-2-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/phi-phi-2.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-phi-phi-2-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-phi-phi-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/phi-phi.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-phi-phi-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-phra-nang-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/phra-nang.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-phra-nang-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-railay-beach-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/p3go/p3go/files/blog/travel/thailand/krabi/railay-beach.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-home-runner-work-p-3-go-p-3-go-files-blog-travel-thailand-krabi-railay-beach-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-term-condition-tsx": () => import("./../../../src/pages/term-condition.tsx" /* webpackChunkName: "component---src-pages-term-condition-tsx" */)
}

